let origin = window.ORIGIN;
//物业评价保存
const addPropertyEvaluateRecord = `${origin}/gateway/hc-neighbour/propertyInfo/addPropertyEvaluateRecord`;
// 物业详情查询（根据房号id）
const getPropertyInfoByHouseId = `${origin}/gateway/hc-neighbour/propertyInfo/getPropertyInfoByHouseId`;
// 物业评价列表查询
const getPropertyEvaluateList = `${origin}/gateway/hc-neighbour/propertyInfo/getPropertyEvaluateList`;
// 话题列表
const getTopicListURL = `${origin}/gateway/hc-neighbour/topic/getTopicList`;
// 查询话题详情
const getTopicInfoByIdURL = `${origin}/gateway/hc-neighbour/topic/getTopicInfoById`;
// 点赞
const addPraiseURL = `${origin}/gateway/hc-neighbour/topic/addPraise`;
// 取消点赞
const cancelPraiseURL = `${origin}/gateway/hc-neighbour/topic/cancelPraise`;
// 进行评论
const addCommentURL = `${origin}/gateway/hc-neighbour/topic/addComment`;
// 话题评论列表分页查询
const getTopicCommentList = `${origin}/gateway/hc-neighbour/topic/getTopicCommentList`;
// 评论回复列表分页查询
const getTopicReplyList = `${origin}/gateway/hc-neighbour/topic/getTopicReplyList`;
// 查询产权人认证审核状态
const getUserHouseOwnerAuditStatusUrl = `${origin}/gateway/blade-user/user/getUserHouseOwnerAuditStatus`;
// 用户信息接口
const userInfoUrl = `${origin}/gateway/hc-mini/user/mini/user-info`;

export {
  getTopicListURL,
  getTopicInfoByIdURL,
  addPraiseURL,
  cancelPraiseURL,
  addCommentURL,
  getTopicCommentList,
  getTopicReplyList,
  addPropertyEvaluateRecord,
  getPropertyInfoByHouseId,
  getPropertyEvaluateList,
  getUserHouseOwnerAuditStatusUrl,
  userInfoUrl,
};
